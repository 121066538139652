import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { HARMONIELLES_PROJECT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';
import ContentSection from '@/components/Portfolio/ContentSection';
import SoloPicture from '@/components/Portfolio/SoloPicture';
import NextProject from '@/components/Portfolio/NextProject';
import DoublePictures from '@/components/Portfolio/DoublePictures';

export default function MM2({ data }) {
  const { t } = useTranslation();

  const tags = ['internal', 'startup', 'ux', 'development', 'growth', 'seo'];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />

      <SoloPicture source={data.cover} alt={t('coverMm2')} />
      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />
      <DoublePictures
        pictures={[
          {
            source: data.frenchweb,
            alt: 'Notion',
          },
          {
            source: data.negoce,
            alt: 'UX/UI formation',
          },
        ]}
      />
      <ContentSection title={t('paragraph2Title')} content={t('paragraph2')} />

      <NextProject title={t('nextProject')} to={HARMONIELLES_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["mm2", "tags", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cover: file(relativePath: { eq: "projects/mm2/app+logos.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    frenchweb: file(relativePath: { eq: "projects/mm2/frenchweb.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    negoce: file(relativePath: { eq: "projects/mm2/negoce.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
